const songs = [
  {
    title: "Suicide -ヴァージン・スーサイド・スカッド-",
    seconds: 52,
    cover: require("../assets/images/virginsuicidesquad.jpg"),
    src: require("../assets/audios/Suicide.mp3"),
  },
  {
    title: "Toxic Moral Avenger -悪魔の道徳モンスター-",
    seconds: 57,
    cover: require("../assets/images/dokudoku.jpg"),
    src: require("../assets/audios/Toxic Moral Avenger.mp3"),
  },
  {
    title: "Pig Bloody Valentine -豚の血のバレンタイン-",
    seconds: 34,
    cover: require("../assets/images/pigbloodyvalentine.jpg"),
    src: require("../assets/audios/Pig Bloody Valentine.mp3"),
  },
  {
    title: "Spellbound -白い恐怖に魅せられて-",
    seconds: 150,
    cover: require("../assets/images/spellbound.jpg"),
    src: require("../assets/audios/Spellbound.mp3"),
  },
  {
    title: "Salam,Peace,Heiwa -平和のえじき-",
    seconds: 71,
    cover: require("../assets/images/salampeace.jpg"),
    src: require("../assets/audios/Salam,Peace,Heiwa.mp3"),
  },
  {
    title: "A Nightmare in Yoyogi Park -ナガタ街の悪夢-",
    seconds: 77,
    cover: require("../assets/images/nightmarengt.jpg"),
    src: require("../assets/audios/A Nigtmare in Yoyogi Park.mp3"),
  },
  {
    title: "Ultime Grida Dalla Monde -グレートハンティングワールド-",
    seconds: 92,
    cover: require("../assets/images/ultima_2.jpg"),
    src: require("../assets/audios/Ultime Grida Dalla Monde.mp3"),
  },
  {
    title: "Children of the Corn -死の収穫祭-",
    seconds: 144,
    cover: require("../assets/images/children.jpg"),
    src: require("../assets/audios/Children of the Corn.mp3"),
  },
  {
    title: "Possibilities to Overcome -ロメロの世界-",
    seconds: 45,
    cover: require("../assets/images/romero.jpg"),
    src: require("../assets/audios/Possibilities to Overcome.mp3"),
  },
  {
    title: "Death Wish 2000 -隣のリベンジャーの家の地獄少女-",
    seconds: 89,
    cover: require("../assets/images/deathwish.jpg"),
    src: require("../assets/audios/Death Wish 2000.mp3"),
  },
  {
    title: "Mash -地獄のマッシュ軍団-",
    seconds: 37,
    cover: require("../assets/images/mash.jpg"),
    src: require("../assets/audios/Mash.mp3"),
  },
  {
    title: "",
    seconds: 178,
    cover: require("../assets/images/oretachi.jpg"),
    src: require("../assets/audios/ORETACHI(俺たち).mp3"),
  },
  {
    title: "Sponteneous Combustion -人体自然発火-",
    seconds: 92,
    cover: require("../assets/images/spontaneous.jpg"),
    src: require("../assets/audios/Sponteneous Combustion.mp3"),
  },
  {
    title: "Revenge of the Living Dead Girls and Boys -死ねば土-",
    seconds: 128,
    cover: require("../assets/images/revenge.jpg"),
    src: require("../assets/audios/Revenge of the Living Dead Girls and Boys.mp3"),
  },
  {
    title: "Urinal Song -惑星ウリネル-",
    seconds: 46,
    cover: require("../assets/images/urinel.jpg"),
    src: require("../assets/audios/Urinal Song.mp3"),
  },
  {
    title: "Ill Magnetic",
    seconds: 125,
    cover: require("../assets/images/ill.jpg"),
    src: require("../assets/audios/Ill Magnetic.mp3"),
  },
  {
    title: "All Dead -人類皆殺し-",
    seconds: 77,
    cover: require("../assets/images/alldead.jpg"),
    src: require("../assets/audios/All Dead.mp3"),
  },
];

export default songs;
