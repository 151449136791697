<template>
  <v-container id="player" tag="section" v-if="bgmPage">
    <v-card class="mb-9">
      <v-img
        :src="require('@/assets/top/bg.jpg')"
        height="100%"
        gradient="45deg,rgba(23,39,116,0.8),rgba(119,217,112,0.8),rgba(255,0,117,.8)"
      >
        <h1
          class="display-1 text-center mb-0 light-green--text text--accent-3 py-9"
        >
          Songs
        </h1>

        <v-row justify="center">
          <v-col cols="12" md="5">
            <main>
              <section class="player">
                <div class="cover-wrapper">
                  <img v-bind:class="coverObject" :src="current.cover" />
                </div>
                <div class="song-details">
                  <h2 class="song-title">{{ current.title }}</h2>
                  <v-progress-linear
                    v-model="percentage"
                    height="5"
                    style="margin-top: 15px; margin-bottom: 15px;"
                    @click.native="setPosition()"
                    :disabled="!loaded"
                  ></v-progress-linear>
                  <div class="timer">
                    <p class="start">
                      {{ currentTime }}
                    </p>
                    <p class="end">
                      {{ current.totalTimer }}
                    </p>
                  </div>
                </div>
                <v-card dark style="text-align: center">
                  <v-card-text>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click="prev"
                    >
                      <v-icon>mdi-skip-previous</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click="play"
                      :disabled="!loaded"
                      v-if="!isPlaying"
                    >
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click="pause"
                      :disabled="!loaded"
                      v-else
                    >
                      <v-icon>mdi-pause</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click="next"
                    >
                      <v-icon>mdi-skip-next</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click.native="stop()"
                      :disabled="!loaded"
                    >
                      <v-icon>mdi-stop</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click.native="mute()"
                      :disabled="!loaded"
                    >
                      <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
                      <v-icon v-else>mdi-volume-mute</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click.native="loaded ? download() : reload()"
                      v-if="!loaded"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      icon
                      class="ma-2"
                      color="success"
                      @click.native="loaded ? download() : reload()"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-slider
                      v-model="playerVolume"
                      prepend-icon="mdi-volume-high"
                      max="1"
                      step="0.01"
                      min="0"
                    ></v-slider>
                  </v-card-text>
                </v-card>
              </section>
            </main>
          </v-col>
          <v-col cols="12" md="5">
            <section class="playlist">
              <h3>Now Playing <span> 🎵 </span></h3>
              <ul>
                <li v-for="song in songs" :key="song.src" class="song">
                  <div class="cover-playlist">
                    <img class="cover" :src="song.cover" />
                  </div>
                  <div class="details" @click="playlist(song)">
                    <h2 class="song-title mb-0">
                      {{ song.title }}
                    </h2>
                  </div>
                </li>
              </ul>
            </section>
          </v-col>
        </v-row>
      </v-img>
    </v-card>

    <div class="text-center">
      <v-btn @click="miniShow" color="#ff0075" dark>MINI PLAYER</v-btn>
    </div>
    <transition name="slideY">
      <v-footer fixed dark v-if="show || nowPlaying">
        <v-row justify="center">
          <v-col md="10">
            <v-card tile>
              <v-progress-linear
                :value="50"
                class="my-0"
                v-model="percentage"
                height="5"
                @click.native="setPosition()"
                :disabled="!loaded"
              ></v-progress-linear>

              <v-list class="py-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ current.title }}</v-list-item-title>
                    <v-list-item-subtitle
                      >ゴメスノナハゴメス</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-spacer></v-spacer>
                  <v-list-item-icon>
                    <v-btn icon @click="prev">
                      <v-icon>mdi-skip-previous</v-icon>
                    </v-btn>
                  </v-list-item-icon>

                  <v-list-item-icon
                    :class="{
                      'mx-5': $vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <v-btn icon @click="play" v-if="!isPlaying">
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                    <v-btn icon @click="pause" v-else>
                      <v-icon>mdi-pause</v-icon>
                    </v-btn>
                  </v-list-item-icon>

                  <v-list-item-icon
                    class="ml-0"
                    :class="{
                      'mr-3': $vuetify.breakpoint.mdAndUp,
                    }"
                  >
                    <v-btn icon @click="next">
                      <v-icon>mdi-skip-next</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-footer>
    </transition>
  </v-container>
</template>

<script>
import { formatTimer } from "@/helpers/timer";
import { threatSongs, shuffleArray } from "@/helpers/utils";
import songs from "@/mocks/songs";

//vuetifyaudio
const formatTime = (second) =>
  new Date(second * 1000).toISOString().substr(11, 8);

export default {
  name: "BGM",
  //vuetifyaudio
  computed: {
    duration: function() {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },
  data() {
    return {
      current: {},
      coverObject: { cover: true, animated: false },
      index: 0,
      isPlaying: false,
      currentlyTimer: "00:00",
      songs: shuffleArray(songs),
      audio: new Audio(),
      //vuetifyaudio
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00:00:00",
      totalDuration: 0,
      playerVolume: 0.5,
      //miniplayer
      show: false,
    };
  },
  methods: {
    listenersWhenPlay() {
      this.audio.addEventListener("timeupdate", () => {
        var playerTimer = this.audio.currentTime;

        this.currentlyTimer = formatTimer(playerTimer);
        this.current.percent = (playerTimer * 100) / this.current.seconds;
        this.current.isPlaying = true;
      });
      this.audio.addEventListener(
        "ended",
        function() {
          this.next();
        }.bind(this)
      );
    },
    setCover() {
      this.coverObject.animated = true;

      setTimeout(() => {
        this.coverObject.animated = false;
      }, 1000);
    },
    setCurrentSong() {
      this.current = this.songs[this.index];
      this.audio.src = this.current.src;
      //this.play(this.current);
      this.audio.play();
      this.setCover();
    },
    playlist(song) {
      if (typeof song.src !== "undefined") {
        this.current = song;
        this.audio.src = this.current.src;
        this.current.isPlaying = false;
        this.index = this.songs.indexOf(this.current);
        this.audio.play().then((_) => (this.isPlaying = true));
        this.$store.commit("setNowPlaying");
      }
    },
    play() {
      this.isPlaying = true;
      this.setCover();
      this.listenersWhenPlay();
      this.audio.play().then((_) => (this.isPlaying = true));
      this.$store.commit("setNowPlaying");
    },
    pause() {
      this.audio.pause();
      this.isPlaying = false;
    },
    next() {
      this.current.isPlaying = false;
      this.index = this.songs.indexOf(this.current);
      this.index++;
      if (this.index > this.songs.length - 1) {
        this.index = 0;
      }
      this.setCurrentSong();
    },
    prev() {
      this.current.isPlaying = false;
      this.index = this.songs.indexOf(this.current);
      this.index--;
      if (this.index < 0) {
        this.index = this.songs.length - 1;
      }
      this.setCurrentSong();
    },
    //vuetifyaudio
    setPosition() {
      this.audio.currentTime = parseInt(
        (this.audio.duration / 100) * this.percentage
      );
    },
    stop() {
      this.audio.pause();
      this.paused = true;
      this.isPlaying = false;
      this.audio.currentTime = 0;
      this.$store.commit("ejectNowPlaying");
      this.show = false;
    },
    /*
        pause() {
            this.paused = !this.paused;
            this.paused ? this.audio.pause() : this.audio.play();
        },
        */
    download() {
      this.audio.pause();
      window.open(this.current.src, "download");
    },
    mute() {
      this.isMuted = !this.isMuted;
      this.audio.muted = this.isMuted;
    },
    reload() {
      this.audio.load();
    },
    _handleLoaded: function() {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {};
            this.audio.currentTime = 0;
            this.totalDuration = parseInt(this.audio.duration);
            this.loaded = true;
          };
        } else {
          this.totalDuration = parseInt(this.audio.duration);
          this.loaded = true;
        }

        if (this.autoPlay) this.audio.play();
      } else {
        throw new Error("Failed to load sound file");
      }
    },
    _handlePlayingUI: function(e) {
      this.audio.volume = this.playerVolume;
      this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      this.currentTime = formatTime(this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function(e) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (
        e.type === "pause" &&
        this.paused === false &&
        this.playing === false
      ) {
        this.currentTime = "00:00:00";
      }
    },
    _handleEnded() {
      this.paused = this.playing = false;
    },
    init: function() {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
    //miniplayer
    miniShow() {
      this.show = !this.show;
    },
  },
  mounted() {
    this.songs = threatSongs(this.songs);
    this.current = this.songs[this.index];
    this.$store.commit("resetHeader");
    //vuetifyaudio
    //this.audio = this.$refs.player;
    this.audio.src = this.current.src;
    this.init();
  },
  computed: {
    bgmPage() {
      return this.$store.state.bgmPage;
    },
    nowPlaying() {
      return this.$store.state.nowPlaying;
    },
  },
  //vuetifyaudio
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
};
</script>

<style>
@import "../../assets/styles/main.css";

.slideY-enter-active,
.slideY-leave-active {
  transition: all 0.5s;
  transform: translateY(0);
}
.slideY-enter,
.slideY-leave-to {
  transform: translateY(100px);
}
</style>
